import type { Breakpoints, Colors, Fonts } from 'types/theme'

import media from 'styles/media'

export const breakpoints: Breakpoints = {
  xxs: 0,
  xs: 360,
  s: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1600,
}

export const colors: Colors = {
  primary: '#3a4f64',
  secondary: '#e72065',
  dark: '#1d1d1d',
  white: '#ffffff',
  white100: '#ffffffc2',
  white200: '#ffffffa2',
  black: '#000000',
}

export const fonts: Fonts = {
  size: '10px',
}

const theme = {
  breakpoints,
  media: media(),
  colors,
  fonts,
}

export default theme
