import { css, createGlobalStyle } from 'styled-components'

const Global = css`
  html {
    font-size: ${({ theme }) => theme.fonts.size};
    overflow-x: hidden;
  }

  body {
    font-family: 'PoppinsExtraLight', sans-serif;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'PoppinsExtraLight', sans-serif;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 300;
  }

  strong {
    font-family: 'PoppinsSemiBold', sans-serif;
  }

  button {
    border: none;
    outline: none;
    font-family: 'PoppinsSemiBold';
    background-color: transparent;
  }

  input,
  textarea {
    font-family: 'PoppinsExtraLight', sans-serif;
    color: ${({ theme }) => theme.colors.white100};
    border: none;
    background-color: transparent;

    &:focus {
      border: none;
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.white100};
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 100px white inset;
    box-shadow: 0 0 0px 100px white inset;
  }

  textarea {
    height: 70px;
    resize: none;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  a {
    text-decoration: none;
  }
`

const GlobalStyles = createGlobalStyle`
    ${Global}
`

export default GlobalStyles
